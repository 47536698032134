import "./modules/equalweb-accessibility-controls";
import "./modules/lazy-load-images";
import "./modules/shop-finder";
import "./modules/toggle-menu";
import "./modules/primary-navigation";
import "./modules/search-toggle";
import "./modules/swipers";
import "./modules/popular-topics";
import "./modules/contact-page-map";
import "./modules/search-results-filtering";
import "./modules/donate-cta";
import "./modules/woocommerce-product-gallery";
//import "./modules/accommodation-booking-request-cta"; 
import "./modules/ticket-cart-form";
import "./modules/upcoming-member-events-filter";
import "./modules/shop-selector";
import "./modules/ajax-filters";

import { Tab, Collapse } from 'bootstrap';